import { Trans } from 'next-i18next';
import React from 'react';
import { Button } from '@/components/atoms/Button';
import useModal from '@/components/modal/useModal';
import { Check, CheckCircle } from '@/icons';

export const TicketSuccessModal: React.FC = () => {
  const { hideAllModals } = useModal();

  return (
    <div className="flex flex-col text-center justify-center gap-2 py-2 px-2.5">
      <h1 className="mx-auto ml-0 lg:hidden text-h2 w-fit">
        <Trans i18nKey="identity.dialog.done" />
      </h1>
      <section className="flex flex-col gap-custom-12">
        <CheckCircle className="w-16 h-16 mx-auto sm:hidden fill-secondary-greendark" />
        <div className="lg:hidden bg-secondary-greenlight2 p-[33px] my-5 rounded-full w-fit mx-auto mb-3">
          <Check className="fill-secondary-green w-[76px]" />
        </div>
        <h1 className="text-secondary-greendark text-h2 sm:hidden">
          <Trans i18nKey="identity.dialog.done" />
        </h1>
      </section>
      <p className="text-neutral-black text-[18px] sm:text-left">
        <Trans i18nKey="identity.dialog.added" />
      </p>
      <Button className="w-full mt-0.5 lg:hidden" size="big" onClick={hideAllModals}>
        <Trans i18nKey="identity.dialog.done" />
      </Button>
    </div>
  );
};
