import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios, { ParamsSerializerOptions } from 'axios';
import qs from 'qs';

const urls = Object.freeze({
  dev: 'https://brn-qa-ybus-pubapi.sa.cz/restapi',
  qa: 'https://brn-qa-ybus-pubapi.sa.cz/restapi',
  prod: 'https://brn-ybus-pubapi.sa.cz/restapi',
});

const origins = Object.freeze({
  dev: 'DEV',
  qa: 'NOT',
  prod: 'NOT',
});

const env = process.env.BUILD_TYPE || 'dev';
const url = urls[env];
const origin = origins[env];

export const paramsSerializer: ParamsSerializerOptions = {
  serialize: (params) => qs.stringify(params, { indices: false }),
};

const TIMEOUT = 30000;

export const api = axios.create({
  baseURL: url,
  timeout: TIMEOUT,
  headers: {
    'Cache-Control': 'no-cache',
    'X-Application-Origin': origin,
    Accept: 'application/json',
  },
  paramsSerializer,
  withCredentials: true,
  transitional: {
    clarifyTimeoutError: true,
  },
});

export const useCaptchaHeader = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  return {
    applyCaptcha: async () => {
      const captchaCode = await executeRecaptcha();
      api.defaults.headers.common['X-ReCaptcha-Token'] = captchaCode;
    },
  };
};
