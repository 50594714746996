import AddTicketIntro from './AddTicketIntro';
import CreateTicketForm from './CreateTicketForm';
import { TicketSuccessModal } from './TicketSuccessModals';
import { Trans } from 'next-i18next';
import React from 'react';
import { Formik } from 'formik';
import Alert from '@/components/atoms/Alert';
import { useIdentityActions } from '@/components/identity/useIdentityActions';
import useModal from '@/components/modal/useModal';
import useFieldValidation, { TCreateTicketValues } from '@/hooks/useFieldValidation';
import { ChevronLeft } from '@/icons';

const initialValues: TCreateTicketValues = {
  name: '',
  surname: '',
  email: '',
  phone: '',
  isCompany: false,
  receiveInfoMails: false,
  // TODO: Remove on commit, checkbox is not working right now
  acceptTerms: true,
  tariff: '',
  currency: '',
};

const CreateNewTicket = (): React.ReactElement => {
  const { yupCreateTicket } = useFieldValidation();
  const { addTicketNewAccount } = useIdentityActions();
  const { showModal } = useModal();

  const handleSubmit = async (values: TCreateTicketValues): Promise<void> => {
    const isRegistered = await addTicketNewAccount(values);
    if (!isRegistered) return;

    showModal(<TicketSuccessModal />, {
      removePadding: true,
      size: 'small',
      deleteBorder: true,
    });
  };

  return (
    <div>
      <h2 className="relative flex items-center justify-center sm:order-first sm:text-start pb-custom-12 lg:text-center text-h2 font-bold sm:w-4/5 sm:text-[22px]">
        <ChevronLeft
          className="absolute left-0 w-6 h-6"
          onClick={() => showModal(<AddTicketIntro />)}
        />
        <Trans i18nKey="notLoggedId.createTicket.title" />
      </h2>

      <p className="pb-[20px]">
        <Trans i18nKey="notLoggedId.createTicket.info" />
      </p>

      <div className="flex flex-col">
        <p className="pb-2">
          <Trans
            i18nKey="notLoggedId.createTicket.confirmInfo"
            components={{ strong: <strong /> }}
          />
        </p>

        <Alert className="text-base" variant="info">
          <Trans i18nKey="notLoggedId.createTicket.alert" />
        </Alert>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={yupCreateTicket}
        >
          {(props) => <CreateTicketForm {...props} />}
        </Formik>
      </div>
    </div>
  );
};

export default CreateNewTicket;
