import Link from 'next/link';
import React from 'react';
import classNames from 'classnames';
import { ClickableElementProps, handleClick, handleKeyPress } from '@/utils/clickableUtils';

export enum ButtonVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SECONDARY_SELECTED = 'secondarySelected',
  TRANSPARENT = 'transparent',
  TRANSPARENT_BLUE = 'transparentBlue',
}

export enum ButtonSizes {
  SMALL = 'small',
  BIG = 'big',
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  Pick<ClickableElementProps<HTMLButtonElement>, 'gtmPlace' | 'gtmName'> {
  /**
   * Varianta (mění barvu textu a pozadí)
   */
  variant?: `${ButtonVariants}`;
  /**
   * Velikost
   */
  size?: `${ButtonSizes}`;
  /**
   * Povolit zalomení textu
   */
  allowTextBreak?: boolean;
}

const mapBaseClassesToVariant = {
  [ButtonVariants.PRIMARY]: 'bg-primary-blue text-white border-none',
  [ButtonVariants.SECONDARY]: 'bg-white text-primary-blue border border-primary-blue',
  [ButtonVariants.SECONDARY_SELECTED]:
    'bg-primary-bluelight3 text-primary-blue border border-primary-blue',
  [ButtonVariants.TRANSPARENT]: 'bg-transparent border border-secondary-yellowdark',
  [ButtonVariants.TRANSPARENT_BLUE]:
    'bg-transparent text-primary-blue border-2 border-primary-blue',
};

const mapInteractiveClassesToVariant = {
  [ButtonVariants.PRIMARY]: 'hover:bg-secondary-bluedark focus-visible:bg-secondary-bluedark',
  [ButtonVariants.SECONDARY]:
    'hover:text-secondary-bluedark hover:border-secondary-bluedark focus-visible:text-secondary-bluedark focus-visible:border-secondary-bluedark',
  [ButtonVariants.SECONDARY_SELECTED]:
    'hover:text-secondary-bluedark hover:border-secondary-bluedark focus-visible:text-secondary-bluedark focus-visible:border-secondary-bluedark',
  [ButtonVariants.TRANSPARENT]: 'hover:border-black focus-visible:border-black',
  [ButtonVariants.TRANSPARENT_BLUE]: 'hover:border-primary-blue focus-visible:border-primary-blue',
};

const mapDisabledClassesToVariant = {
  [ButtonVariants.PRIMARY]: 'bg-neutral-gray2',
  [ButtonVariants.SECONDARY]: 'text-neutral-gray2 border-neutral-gray2',
  [ButtonVariants.TRANSPARENT]: 'text-neutral-gray2',
  [ButtonVariants.TRANSPARENT_BLUE]: 'text-neutral-gray2 border-neutral-gray2',
};

const mapClassesToSize = {
  [ButtonSizes.SMALL]: 'h-5',
  [ButtonSizes.BIG]: 'h-12',
};

const getClasses = ({ variant, disabled, size, className, allowTextBreak }) =>
  classNames(
    'inline-flex items-center justify-center px-2.5 rounded-sm font-bold transition focus:outline-none focus-visible:outline-none focus-visible:shadow-border',
    disabled ? 'cursor-default' : 'hover:shadow-modal cursor-pointer',
    mapBaseClassesToVariant[variant],
    !disabled && mapInteractiveClassesToVariant[variant],
    disabled && mapDisabledClassesToVariant[variant],
    !allowTextBreak && 'whitespace-nowrap',
    mapClassesToSize[size],
    className,
  );

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  variant = ButtonVariants.PRIMARY,
  size = ButtonSizes.SMALL,
  allowTextBreak,
  type = 'button',
  disabled,
  onClick,
  gtmPlace,
  gtmName,
  ...props
}) => (
  <button
    className={getClasses({ variant, disabled, size, className, allowTextBreak })}
    // eslint-disable-next-line react/button-has-type
    type={type}
    disabled={disabled}
    onClick={handleClick({ onClick, gtmPlace, gtmName })}
    onKeyPress={handleKeyPress({ onClick, gtmPlace, gtmName })}
    {...props}
  >
    {children}
  </button>
);

/**
 * Vizuálně stejná komponenta jako `Button`
 * Jediný rozdíl je změna HTML tagu na `a` + forwardRef
 * Obalovací Nextový link musí mít atribut `passHref`
 */
export interface ButtonLinkProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  /**
   * Varianta (mění barvu textu a pozadí)
   */
  variant?: `${ButtonVariants}`;
  /**
   * Velikost
   */
  size?: `${ButtonSizes}`;
  /**
   * Povolit zalomení textu
   */
  allowTextBreak?: boolean;
}

export type ButtonLinkPropsWithHref = Omit<ButtonLinkProps, 'href'> & {
  href?:
  | string
  | { pathname?: string; query?: Record<string, string | number | boolean | string[]>; hash?: string };
};

// eslint-disable-next-line react/display-name
export const ButtonLink: React.FC<ButtonLinkPropsWithHref> = ({
  href,
  children,
  className,
  variant = ButtonVariants.PRIMARY,
  size = ButtonSizes.SMALL,
  allowTextBreak,
  ...props
}) => {
  const isExternal =
    href && typeof href === 'string' && (href.startsWith('http') || href.startsWith('mailto'));

  const button = (
    <a
      href={isExternal ? href : undefined} // href will be provided by Next.js Link for internal links
      className={getClasses({ variant, disabled: false, size, className, allowTextBreak })}
      {...props}
    >
      {children}
    </a>
  );

  return isExternal ? (
    button
  ) : (
    <Link href={href} passHref>
      {button}
    </Link>
  );
};
