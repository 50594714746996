import { TicketSuccessModal } from './TicketSuccessModals';
import { Trans, useTranslation } from 'next-i18next';
import * as React from 'react';
import { Form, Formik } from 'formik';
import Alert from '@/components/atoms/Alert';
import { Button } from '@/components/atoms/Button';
import Input from '@/components/atoms/Input';
import { useIdentityActions } from '@/components/identity/useIdentityActions';
import FullScreenLoader from '@/components/loader/FullScreenLoader';
import useModal from '@/components/modal/useModal';
import useFieldValidation, { TAddTicketValues } from '@/hooks/useFieldValidation';

const initialValues: TAddTicketValues = {
  password: '',
  ticketNumber: '',
};

const AddTicket = (): React.ReactElement => {
  const { t } = useTranslation();
  const { yupAddTicket } = useFieldValidation();
  const { addTicketExistingAccount } = useIdentityActions();
  const { showModal } = useModal();

  const handleSubmit = async (values: TAddTicketValues): Promise<void> => {
    const isSuccessful = await addTicketExistingAccount(values);
    if (!isSuccessful) return;

    showModal(<TicketSuccessModal />, {
      removePadding: true,
      size: 'small',
      deleteBorder: true,
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={yupAddTicket}>
      {({ errors, handleChange, handleBlur, touched, values, isSubmitting }) => (
        <Form className="flex flex-col h-full">
          <h2 className="sm:order-first sm:text-start pb-custom-12 lg:text-center text-h2 font-bold sm:w-4/5 sm:text-[22px]">
            <Trans i18nKey="notLoggedId.addTicket.title" />
          </h2>

          <span className="sm:hidden sm:pt-2 sm:text-start sm:text-neutral-gray lg:pb-[20px] lg:text-center">
            <Trans i18nKey="notLoggedId.addTicket.subTitle" />
          </span>

          <span className="font-bold pb-2 lg:hidden">
            <Trans i18nKey="notLoggedId.addTicket.mobileTitle" />
          </span>

          <Input
            className="sm:order-2"
            errorText={errors.ticketNumber && touched.ticketNumber && errors.ticketNumber}
            id="ticketNumber"
            label={t('notLoggedId.addTicket.inputTicketNumber')}
            name="ticketNumber"
            onBlur={handleBlur}
            onChange={handleChange}
            onlyNumbers
            value={values.ticketNumber}
          />

          <Alert className="sm:order-3 my-2 text-base" variant="info">
            <Trans i18nKey="notLoggedId.addTicket.alert" />
          </Alert>

          <div className="flex flex-col gap-custom-12 sm:order-2 sm:pt-custom-12">
            <Input
              errorText={errors.password && touched.password && errors.password}
              id="password"
              label={t('notLoggedId.addTicket.inputPassword')}
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              password
              value={values.password}
            />
            <span className="text-primary-blue cursor-pointer">
              <Trans i18nKey="notLoggedId.addTicket.forgottenPassword" />
            </span>
          </div>

          <div className="flex sm:flex-col-reverse lg:flex-col order-last mt-auto">
            <Button
              disabled={isSubmitting}
              className="w-full h-12 px-3 py-[10px] lg:mt-[20px]"
              type="submit"
            >
              <Trans i18nKey="notLoggedId.addTicket.buttonContinue" />
            </Button>

            <span className="sm:mb-1 py-[10px] px-1 lg:mt-1 text-center text-primary-blue cursor-pointer">
              <Trans i18nKey="notLoggedId.addTicket.createNewTicket" />
            </span>
          </div>

          <FullScreenLoader showLoader={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default AddTicket;
