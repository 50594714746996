import AddTicket from './AddTicket';
import CreateNewTicket from './CreateNewTicket';
import PaymentCardSvg from './assets/payment_carts.svg';
import { Trans } from 'next-i18next';
import * as React from 'react';
import { Button, ButtonVariants } from '@/components/atoms/Button';
import useModal from '@/components/modal/useModal';

const buttons = [
  { key: 'buttonContinue', variant: ButtonVariants.PRIMARY },
  { key: 'createNewTicket', variant: ButtonVariants.SECONDARY },
];

const AddTicketIntro = (): React.ReactElement => {
  const { showModal } = useModal();

  const handleModalVariants = (variant: ButtonVariants): void => {
    if (variant === ButtonVariants.PRIMARY) {
      showModal(<AddTicket />, { className: 'sm:h-[calc(100dvh-6.5rem)] lg:h-fit' });
      return;
    }
    showModal(<CreateNewTicket />);
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <h2 className="text-center sm:text-[22px] w-4/5 text-h2 font-bold">
        <Trans i18nKey="notLoggedId.addTicketIntro.title" />
      </h2>

      <PaymentCardSvg />

      <div className="flex flex-col gap-custom-12">
        <h3 className="text-center text-h3">
          <Trans i18nKey="notLoggedId.addTicketIntro.subTitle" />
        </h3>

        <p>
          <Trans i18nKey="notLoggedId.addTicketIntro.paragraphInfo" />
        </p>
      </div>

      <div className="flex justify-center sm:flex-col sm:gap-1 gap-custom-12 w-full">
        {buttons.map(({ key, variant }) => (
          <Button
            key={key}
            onClick={(): void => handleModalVariants(variant)}
            className="w-full h-12 px-3 py-[10px]"
            variant={variant}
          >
            <Trans i18nKey={`notLoggedId.addTicket.${key}`} />
          </Button>
        ))}
      </div>
    </div>
  );
};

export default AddTicketIntro;
