import Tariff from '../registration/tariffOptions';
import { useIdentity } from './IdentityProvider';
import { api, useCaptchaHeader } from './SharedIdentityClient';
import { useHeaders } from '@web/shop-logic';
import type { TAddTicketValues, TCreateTicketValues } from '@/hooks/useFieldValidation';

interface IdentityActions {
  addTicketExistingAccount: (values: TAddTicketValues) => Promise<boolean>;
  addTicketNewAccount: (values: TCreateTicketValues) => Promise<boolean>;
}

const API_ENDPOINTS = {
  ACTION_TOKEN: '/users/actionToken',
  ACCOUNTS: (sub: string) => `/identities/${sub}/accounts`,
  SIGNUP: '/users/signup/registeredAccount',
  LOGIN: '/users/login/registeredAccount',
};

export const useIdentityActions = (): IdentityActions => {
  const { session } = useIdentity();
  const { language } = useHeaders();
  const { applyCaptcha } = useCaptchaHeader();

  const addTicket = async (newToken: string) => {
    const tokenRequest = await api.get(API_ENDPOINTS.ACTION_TOKEN, {
      headers: { Authorization: `Bearer ${newToken}` },
    });

    const ticketRequest = await api.post(
      API_ENDPOINTS.ACCOUNTS(session.sub),
      {},
      {
        headers: {
          Authorization: `Bearer ${newToken}`,
          'X-TxToken': tokenRequest.data,
          'X-Lang': language,
        },
      },
    );

    return ticketRequest.status === 200;
  };

  const addTicketNewAccount = async (values: TCreateTicketValues): Promise<boolean> => {
    await applyCaptcha();

    /**
     * Use more safer alternative to Math.random for generating password
     * TODO: To be removed when YBUS modifies the endpoint to remove the required password field
     */
    const randomPassword = crypto.getRandomValues(new Uint8Array(8)).join('');

    const accountResponse = await api.post(API_ENDPOINTS.SIGNUP, {
      agreeWithTerms: values.acceptTerms,
      company: {
        address: '',
        companyName: '',
        registrationNumber: '',
        vatNumber: '',
      },
      companyInformation: values.isCompany,
      currency: values.currency,
      defaultTariff: Tariff.Regular,
      email: values.email,
      firstName: values.name,
      notifications: {
        newsletter: values.receiveInfoMails,
        reservationChange: values.receiveInfoMails,
        routeRatingSurvey: values.receiveInfoMails,
      },
      password: randomPassword,
      phoneNumber: values.phone.replaceAll(' ', ''),
      surname: values.surname,
    });
    if (accountResponse.status !== 200) return false;

    const isAdded = await addTicket(accountResponse.data.token);
    return isAdded;
  };

  const addTicketExistingAccount = async (values: TAddTicketValues) => {
    const response = await api.post(API_ENDPOINTS.LOGIN, {
      accountCode: values.ticketNumber.toString(),
      password: values.password,
    });

    if (response.status !== 200) return false;

    const isLinked = await addTicket(response.data.token);
    return isLinked;
  };

  return { addTicketExistingAccount, addTicketNewAccount };
};
