import AddTicketIntro from '../account/addNewTicket/AddTicketIntro';
import useModal from '../modal/useModal';
import { useIdentity } from './IdentityProvider';
import React, { useEffect } from 'react';

const MODAL_KEY = 'identityFirstTimeDialogHidden';

export const IdentityFirstTimeDialog: React.FC = () => {
  const { connectedTickets } = useIdentity();
  const { showModal } = useModal();

  useEffect(() => {
    const shouldShowModal =
      connectedTickets?.length === 0 && localStorage.getItem(MODAL_KEY) !== 'true';

    if (shouldShowModal) {
      showModal(<AddTicketIntro />, {
        // This prevents settings the storage value when website re-renders and then not opening again
        onClose: () => localStorage.setItem(MODAL_KEY, 'true')
      });
    }
  }, [connectedTickets]);

  return null;
};
