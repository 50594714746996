import { Trans, useTranslation } from 'next-i18next';
import * as React from 'react';
import { Form, FormikProps } from 'formik';
import { useTariffs } from '@web/shop-logic';
import { Button } from '@/components/atoms/Button';
import Checkbox from '@/components/atoms/Checkbox';
import Input from '@/components/atoms/Input';
import SelectInput from '@/components/atoms/SelectInput';
import { headerCurrencies } from '@/components/header/headerLangAndCurrency';
import FullScreenLoader from '@/components/loader/FullScreenLoader';
import { TCreateTicketValues } from '@/hooks/useFieldValidation';
import { ChevronDown } from '@/icons';

const hrefProps = {
  target: '_blank',
  className: 'underline',
  rel: 'noreferrer',
};

const CreateTicketForm = ({
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
  isSubmitting,
}: FormikProps<TCreateTicketValues>): React.ReactElement => {
  const { t } = useTranslation();
  const { data } = useTariffs();

  return (
    <Form className="flex flex-col gap-custom-12 pt-custom-12">
      <div className="flex gap-custom-12 w-full">
        <Input
          className="w-full"
          errorText={errors.name && touched.name && errors.name}
          id="name"
          label={t('notLoggedId.createTicket.inputName')}
          name="name"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          value={values.name}
        />

        <Input
          className="w-full"
          errorText={errors.surname && touched.surname && errors.surname}
          id="surname"
          label={t('notLoggedId.createTicket.inputSurname')}
          name="surname"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          value={values.surname}
        />
      </div>

      <Input
        errorText={errors.email && touched.email && errors.email}
        id="email"
        label={t('notLoggedId.createTicket.inputEmail')}
        name="email"
        onBlur={handleBlur}
        onChange={handleChange}
        type="email"
        value={values.email}
      />

      <Input
        errorText={errors.phone && touched.phone && errors.phone}
        id="phone"
        label={t('notLoggedId.createTicket.inputPhone')}
        name="phone"
        onBlur={handleBlur}
        onChange={handleChange}
        type="tel"
        value={values.phone}
      />

      {/* Checkbox for company */}
      <Checkbox
        checked={values.isCompany}
        errorText={errors.isCompany && touched.isCompany && errors.isCompany}
        id="isCompany"
        label={t('notLoggedId.createTicket.checkBoxCompany')}
        name="isCompany"
        onChange={handleChange}
        variant="alternative"
      />

      <h4 className="text-h4">
        <Trans i18nKey="notLoggedId.createTicket.ticketSettingsTitle" />
      </h4>

      {/* Whole SelectBlock TODO */}
      <div className="flex gap-custom-12">
        <SelectInput
          className="w-full"
          SelectProps={{
            IconComponent: () => (
              <ChevronDown className="absolute right-1 w-3 max-h-3 flex-shrink-0 pointer-events-none fill-neutral-gray2" />
            ),
          }}
          options={data.map((option) => ({
            label: option.value,
            value: option.key,
          }))}
          value={values.tariff}
          name="tariff"
          label={t('notLoggedId.createTicket.passenger')}
          onChange={handleChange}
        />

        <SelectInput
          name="currency"
          className="w-[142px]"
          SelectProps={{
            IconComponent: () => (
              <ChevronDown className="absolute right-1 w-3 max-h-3 flex-shrink-0 pointer-events-none fill-neutral-gray2" />
            ),
          }}
          options={headerCurrencies.map((option) => ({
            value: option,
            label: option,
          }))}
          value={values.currency}
          label={t('notLoggedId.createTicket.currency')}
          onChange={handleChange}
        />
      </div>

      <div className="flex flex-col gap-[4px]">
        {/* Checkbox for receiving info mails */}
        <Checkbox
          checked={values.receiveInfoMails}
          errorText={errors.receiveInfoMails && touched.receiveInfoMails && errors.receiveInfoMails}
          id="receiveInfoMails"
          label={t('notLoggedId.createTicket.checkBoxInfoMails')}
          name="receiveInfoMails"
          onChange={handleChange}
          variant="alternative"
        />

        {/* Checkbox for accepting terms */}
        <Checkbox
          checked={values.acceptTerms}
          errorText={errors.acceptTerms && touched.acceptTerms && errors.acceptTerms}
          label={
            <Trans
              i18nKey="notLoggedId.createTicket.termsAndConditions"
              components={{
                transportConditions: (
                  <a href={t('ticket.passengerModal.info.transportRules')} {...hrefProps} />
                ),
                linkPrivacy: (
                  <a href={t('ticket.passengerModal.info.conditionsUrl')} {...hrefProps} />
                ),
              }}
            />
          }
          name="acceptTerms"
          onChange={handleChange}
          variant="alternative"
        />
      </div>

      <Button disabled={isSubmitting} className="w-full h-12 px-3 py-[10px]" type="submit">
        <Trans i18nKey="notLoggedId.createTicket.createButton" />
      </Button>

      <FullScreenLoader showLoader={isSubmitting} />
    </Form>
  );
};

export default CreateTicketForm;
