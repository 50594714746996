import { api } from './SharedIdentityClient';
import { getCookie, setCookie } from 'cookies-next';
import { Session } from 'next-auth';
import { useRouter } from 'next/router';
import { signIn, signOut, useSession, SessionProvider } from 'next-auth/react';
import * as React from 'react';
import { captureMessage } from '@sentry/nextjs';
import { Currency } from '@web/shop-logic/dist/hooks/types';
import env from '@/constants/env';
import { createRegistrationUrl } from '@/utils/createRegistrationUrl';

interface IdentityContextProps {
  session: Session;
  loading: boolean;
  connectedTickets?: IdentityConnectedTicket[];
  register: () => void;
  handleSignIn: () => void;
  handleSignOut: () => void;
}

interface IdentityConnectedTicket {
  code: string;
  balance: {
    amount: number;
    currency: Currency;
  };
}

const IdentityContext = React.createContext<IdentityContextProps | undefined>(undefined);

const register = () => {
  window.location.href = createRegistrationUrl(
    `${env.KEYCLOAK_ISSUER}/protocol/openid-connect/registrations`,
    env.KEYCLOAK_CLIENT_ID,
    `${window.location.origin}/api/auth/callback/keycloak`,
  );
};

const IdentityProviderInner: React.FC = ({ children }: { children: React.ReactNode }) => {
  const { asPath, isReady } = useRouter();
  const { data: session, status } = useSession();
  const [signInInitiated, setSignInInitiated] = React.useState(false);
  const [connectedTickets, setConnectedTickets] = React.useState<
    IdentityConnectedTicket[] | undefined
  >();

  const urlParams = new URLSearchParams(asPath.split('?')[1]);
  const codeParam = urlParams.has('code');

  const loading = status === 'loading';

  React.useEffect(() => {
    if (!isReady || signInInitiated) return;

    const callbackUrl = getCookie('next-auth.callback-url') as string;
    const csrfToken = getCookie('next-auth.csrf-token') as string;

    if (codeParam) {
      setCookie('next-auth.callback-url', window.location.href);
      setSignInInitiated(true);

      /**
       * This is required, along with the pages configuration { signIn: '/' },
       * to authenticate the user after they are redirected from the email following registration.
       * */
      signIn('keycloak', { callbackUrl: '/' }).catch((error) => {
        captureMessage(`NextAuth sign in failed: ${error.message}`);
        setSignInInitiated(false);
      });
    } else if (status === 'unauthenticated' && callbackUrl && csrfToken) {
      setSignInInitiated(true);

      signIn('keycloak').catch((error) => {
        captureMessage(`NextAuth sign in failed: ${error.message}`);
        setSignInInitiated(false);
      });
    }
  }, [isReady, asPath, status, codeParam, signInInitiated]);

  React.useEffect(() => {
    if (!session) return;

    api
      .get(`/identities/${session?.sub}/accounts`, {
        headers: {
          Authorization: `Bearer ${session?.accessToken}`,
        },
      })
      .then((response) => {
        setConnectedTickets(response.data);
      })
      .catch((error) => {
        captureMessage(`NextAuth get connected tickets failed: ${error.message}`);
      });
  }, [session]);

  const handleSignOut = React.useCallback(() => {
    signOut().catch((error) => {
      captureMessage(`NextAuth sign out failed: ${error.message}`);
    });
  }, []);

  const handleSignIn = React.useCallback(() => {
    signIn('keycloak').catch((error) => {
      captureMessage(`NextAuth sign in failed: ${error.message}`);
    });
  }, []);

  return (
    <IdentityContext.Provider
      value={{
        session: session,
        connectedTickets,
        loading,
        register,
        handleSignIn,
        handleSignOut,
      }}
    >
      {children}
    </IdentityContext.Provider>
  );
};

const IdentityProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <SessionProvider>
      <IdentityProviderInner>{children}</IdentityProviderInner>
    </SessionProvider>
  );
};

export const useIdentity = (): IdentityContextProps => {
  return React.useContext(IdentityContext);
};

export default IdentityProvider;
