const URLS = {
  ACCOUNT: '/account',
  CAREER: '/career',
  CONTACT_FORM: '/contact-form',
  COOKIES: '/cookies',
  CREDIT: '/credit',
  DELAYS: '/delays',
  HOMEPAGE: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  MAGAZINE_LIST: '/magazine-list',
  MAP: '/map',
  MEDIA: '/media-news',
  PASSWORD_RESET: '/password-reset',
  PAYMENT_REDIRECT_GATE: '/payment-redirect-gate',
  PAYMENTS: '/payments',
  REGISTRATION: '/registration',
  PRICELIST: '/pricelist',
  RESERVATION: {
    ADDONS: '/reservation/addons',
    CART: '/reservation/cart',
    CHECKOUT: '/reservation/checkout',
    FARE: '/reservation/fare',
    PASSENGERS: '/reservation/passengers',
    SEATING: '/reservation/seating',
    SUCCESS: '/reservation/success',
  },
  REQUEST_PASSWORD_RESET: '/request-password-reset',
  REVIEW: '/review',
  STATIONS: '/stations',
  TICKETS: '/tickets',
  BLOG: {
    HOME: '/blog',
    AUTHORS: '/blog/authors',
    CATEGORY: '/blog/category',
  },
  EXCLUSIONS: {
    INDEX: '/exclusions',
  }
};

export const HASHES = {
  STATIONS: { PRICELIST: 'pricelist', TIMETABLES: 'timetables' },
};

/**
 * Odpovídá poli `slug` vyplněné u jednotlivých `page_categories`
 */
export const STRAPI_SLUGS = {
  // Různé URL podle lokalizace kategorie Kariéra
  CAREER: {
    cs: 'kariera',
    de: 'karriere',
    'de-AT': 'karriere',
    sk: 'kariera',
  },
  PRICELIST: { cs: 'jizdne-a-ceny' },
  TIMETABLES: {
    at: 'wohin-fahren-wir/fahrplane',
    cs: 'kam-jezdime/jizdni-rady',
    de: 'wohin-fahren-wir/fahrplane',
    en: 'where-we-go/time-tables',
    hu: 'menetrendek-es-megallok/menetrendek',
    pl: 'dokad-jezdzimy/rozklady-jazdy',
    sk: 'kam-jazdime/cestovne-poriadky',
    uk: 'napryamki/rozklad-rukhu',
  },
  MEDIA: { cs: 'pro-media', sk: 'pre-media' },
};

/**
 * API endpointy pro jednotlivé `collection_type`
 */
export const STRAPI_ENDPOINTS = {
  BLOG: {
    PAGES: 'blog-pages',
    CATEGORY: 'blog-categories',
    AUTHORS: 'blog-authors',
    TAGS: 'blog-tags',
  },
  EXCLUSIONS: 'exclusions',
  CATEGORY_FILTER: 'page_category', // filter slug, ne endpoint
  PAGES: 'pages',
  TEMPLATES: 'page-templates',
  FARES_IMAGES: 'shop-fares-images',
  PAGE_CATEGORIES: 'page-categories',
};

export default URLS;
